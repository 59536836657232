<template>
  <div style="background:#fff">
    <div @click="goBack">
       <div class="header P_2">
        <img src="../assets/icon30.png" alt="">
        <span>关于萝卜猎手投后服务</span>
      </div>
    </div>
    <div style="padding:1rem;">
      <img src="../assets/page1.jpg" alt="">
      <img src="../assets/page2.jpg" alt="">
      <img src="../assets/page3.jpg" alt="">
      <img src="../assets/page4.jpg" alt="">
      <img src="../assets/page5.jpg" alt="">
      <img src="../assets/page6.jpg" alt="">
      <img src="../assets/page7.jpg" alt="">
      <img src="../assets/page8.jpg" alt="">
      <img src="../assets/page9.jpg" alt="">
      <img src="../assets/page10.jpg" alt="">
      <img src="../assets/page11.jpg" alt="">
      <img src="../assets/page12.jpg" alt="">
      <img src="../assets/page13.jpg" alt="">
      <img src="../assets/page14.jpg" alt="">
      <!-- 关于萝卜猎手投后服务 -->
    </div>
     
  </div>
</template>
<script>
import * as echarts from 'echarts';
export default {
  data(){
    return {

    }
  },
  mounted(){
    
  },
  methods:{
     
    goBack(){
      this.$router.go(-1)
    },
  }
}
</script>